// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fonts-tester-js": () => import("./../../../src/pages/fonts-tester.js" /* webpackChunkName: "component---src-pages-fonts-tester-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-uap-1-js": () => import("./../../../src/pages/uap/1.js" /* webpackChunkName: "component---src-pages-uap-1-js" */),
  "component---src-pages-uap-10-js": () => import("./../../../src/pages/uap/10.js" /* webpackChunkName: "component---src-pages-uap-10-js" */),
  "component---src-pages-uap-11-js": () => import("./../../../src/pages/uap/11.js" /* webpackChunkName: "component---src-pages-uap-11-js" */),
  "component---src-pages-uap-12-js": () => import("./../../../src/pages/uap/12.js" /* webpackChunkName: "component---src-pages-uap-12-js" */),
  "component---src-pages-uap-13-js": () => import("./../../../src/pages/uap/13.js" /* webpackChunkName: "component---src-pages-uap-13-js" */),
  "component---src-pages-uap-14-js": () => import("./../../../src/pages/uap/14.js" /* webpackChunkName: "component---src-pages-uap-14-js" */),
  "component---src-pages-uap-15-js": () => import("./../../../src/pages/uap/15.js" /* webpackChunkName: "component---src-pages-uap-15-js" */),
  "component---src-pages-uap-16-js": () => import("./../../../src/pages/uap/16.js" /* webpackChunkName: "component---src-pages-uap-16-js" */),
  "component---src-pages-uap-17-js": () => import("./../../../src/pages/uap/17.js" /* webpackChunkName: "component---src-pages-uap-17-js" */),
  "component---src-pages-uap-18-js": () => import("./../../../src/pages/uap/18.js" /* webpackChunkName: "component---src-pages-uap-18-js" */),
  "component---src-pages-uap-19-js": () => import("./../../../src/pages/uap/19.js" /* webpackChunkName: "component---src-pages-uap-19-js" */),
  "component---src-pages-uap-2-js": () => import("./../../../src/pages/uap/2.js" /* webpackChunkName: "component---src-pages-uap-2-js" */),
  "component---src-pages-uap-20-js": () => import("./../../../src/pages/uap/20.js" /* webpackChunkName: "component---src-pages-uap-20-js" */),
  "component---src-pages-uap-21-js": () => import("./../../../src/pages/uap/21.js" /* webpackChunkName: "component---src-pages-uap-21-js" */),
  "component---src-pages-uap-22-js": () => import("./../../../src/pages/uap/22.js" /* webpackChunkName: "component---src-pages-uap-22-js" */),
  "component---src-pages-uap-23-js": () => import("./../../../src/pages/uap/23.js" /* webpackChunkName: "component---src-pages-uap-23-js" */),
  "component---src-pages-uap-3-js": () => import("./../../../src/pages/uap/3.js" /* webpackChunkName: "component---src-pages-uap-3-js" */),
  "component---src-pages-uap-4-js": () => import("./../../../src/pages/uap/4.js" /* webpackChunkName: "component---src-pages-uap-4-js" */),
  "component---src-pages-uap-5-js": () => import("./../../../src/pages/uap/5.js" /* webpackChunkName: "component---src-pages-uap-5-js" */),
  "component---src-pages-uap-6-js": () => import("./../../../src/pages/uap/6.js" /* webpackChunkName: "component---src-pages-uap-6-js" */),
  "component---src-pages-uap-7-js": () => import("./../../../src/pages/uap/7.js" /* webpackChunkName: "component---src-pages-uap-7-js" */),
  "component---src-pages-uap-8-js": () => import("./../../../src/pages/uap/8.js" /* webpackChunkName: "component---src-pages-uap-8-js" */),
  "component---src-pages-uap-9-js": () => import("./../../../src/pages/uap/9.js" /* webpackChunkName: "component---src-pages-uap-9-js" */),
  "component---src-pages-uap-analyzing-the-answers-js": () => import("./../../../src/pages/uap/analyzing-the-answers.js" /* webpackChunkName: "component---src-pages-uap-analyzing-the-answers-js" */),
  "component---src-pages-uap-js": () => import("./../../../src/pages/uap.js" /* webpackChunkName: "component---src-pages-uap-js" */)
}

